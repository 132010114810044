import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import {
    Page,
    SectionHero,
    SectionHowItWorks,
    SectionHeroFeatures,
    SectionClientTestimonials,
    SectionLocations,
    LayoutSingleColumn,
    LayoutWrapperTopbar,
    LayoutWrapperMain,
    LayoutWrapperFooter,
    Footer,
    NamedLink,
    Button,
} from '../../components';
import { TopbarContainer } from '../../containers';

import facebookImage from '../../assets/saunatimeFacebook-1200x630.jpg';
import twitterImage from '../../assets/saunatimeTwitter-600x314.jpg';
import css from './LandingPage.module.css';

export const LandingPageComponent = props => {
    const { history, intl, location, scrollingDisabled } = props;

    // Schema for search engines (helps them to understand what this page is about)
    // http://schema.org
    // We are using JSON-LD format
    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage(
        { id: 'LandingPage.schemaTitle' },
        { siteTitle }
    );
    const schemaDescription = intl.formatMessage({
        id: 'LandingPage.schemaDescription',
    });
    const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

    return (
        <Page
            className={css.root}
            scrollingDisabled={scrollingDisabled}
            contentType="website"
            description={schemaDescription}
            title={schemaTitle}
            facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
            twitterImages={[
                {
                    url: `${config.canonicalRootURL}${twitterImage}`,
                    width: 600,
                    height: 314,
                },
            ]}
            schema={{
                '@context': 'http://schema.org',
                '@type': 'WebPage',
                description: schemaDescription,
                name: schemaTitle,
                image: [schemaImage],
            }}>
            <LayoutSingleColumn>
                <LayoutWrapperTopbar>
                    <TopbarContainer />
                </LayoutWrapperTopbar>
                <LayoutWrapperMain>
                    <div className={css.heroContainer}>
                        <SectionHero
                            className={css.hero}
                            history={history}
                            location={location}
                        />
                    </div>
                    <li className={css.section}>
                            <div
                                className={css.sectionContent}
                                style={{ }}>
                                <SectionHowItWorks />
                            </div>
                        </li>
                        <li className={css.section}>
                            <div
                                className={css.sectionContent}
                                style={{ marginTop: '20px' }}>
                                <SectionHeroFeatures />
                            </div>
                        </li>
                    <ul className={css.sections}>
                        <li className={css.section}>
                            <div className={css.sectionContentFirstChild}>
                                <SectionLocations />
                            </div>
                        </li>
                     
                 
                        <hr className={css.sectionSeperator} />
                        <li style={{margin: '60px 0'}} className={css.section}>
                            <div className={css.testimonialsSection}>
                                <SectionClientTestimonials />
                            </div>
                        </li>
                    </ul>
                    <div className={css.footerContainer}>
                        <div className={css.footerContent}>
                            <div className={css.subContentContainer}>
                                <h1 className={css.footerMainTitle}>
                                    <FormattedMessage id="SectionFooter.title" />
                                </h1>
                                <h2 className={css.footerSubTitle}>
                                    <FormattedMessage id="SectionFooter.subTitle" />
                                </h2>
                            </div>
                            <Button className={css.footerButton}>
                                <NamedLink name="SignupPage">
                                    <FormattedMessage id="SectionFooter.signupButton" />
                                </NamedLink>
                            </Button>
                        </div>
                    </div>
                </LayoutWrapperMain>
                <LayoutWrapperFooter>
                    <Footer />
                </LayoutWrapperFooter>
            </LayoutSingleColumn>
        </Page>
    );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
    scrollingDisabled: bool.isRequired,

    // from withRouter
    history: object.isRequired,
    location: object.isRequired,

    // from injectIntl
    intl: intlShape.isRequired,
};

const mapStateToProps = state => {
    return {
        scrollingDisabled: isScrollingDisabled(state),
    };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(
    withRouter,
    connect(mapStateToProps),
    injectIntl
)(LandingPageComponent);

export default LandingPage;
